// Critical render path styles
:root {
    color-scheme: light;
}

html {
    // background: $primary;
    // background-image:url(../img/new-bg.webp);
    // background-position: center;
    // background-size:cover;
    // background-attachment: fixed;
    scroll-behavior: smooth;
    opacity: 1;
    visibility: visible;
}

// Prevent FOUC
.no-js {
    opacity: 0;
}

html.js {
    opacity: 1;
    transition: opacity 0.1s ease-in;
}

// Background canvas
#box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #2d5283;
}

// Optimize modal display
.reveal {
    backface-visibility: hidden;
    will-change: transform, opacity;
    contain: layout style paint;
    
    &[aria-hidden="true"] {
        display: none;
    }
    
    &[aria-hidden="false"] {
        display: block;
    }
}

// Optimize white container performance
.white-container {
    position: relative;
    background: white;
    padding-top: clamp(80px, 10vw, 160px);
    padding-bottom: clamp(60px, 8vw, 120px);
    max-width: 1300px;
    width: 90%;
    padding-left: clamp(15px, 4vw, 80px);
    padding-right: clamp(15px, 4vw, 80px);
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1),
                transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: opacity, transform;
    contain: layout style paint;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    &.is-visible {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    @include breakpoint(xlarge down) {
        padding-top: 100px;
        padding-bottom: 80px;
    }
    @include breakpoint(large down) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @include breakpoint(small down) {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        width: 90%;
    }
}

// Optimize image loading
img {
    content-visibility: auto;
    contain: layout style paint;
}

a {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display:inline-block;
  position: relative;
  color: $primary;
  // &:after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   transform: scaleX(0);
  //   height: 2px;
  //   bottom: -2px;
  //   left: 0;
  //   background-color: $primary;
  //   transform-origin: bottom right;
  //   transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  // }

}

body {
  background: transparent;
  font-size: 24px;
  padding-top: 100px;
  padding-bottom: 100px;
  @include breakpoint(xlarge down){
    font-size: 20px;
  }
  @include breakpoint(small down){
    font-size: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

section {
  margin-top:160px;
  margin-bottom:200px;
  &:last-of-type {
    margin-top:260px;
    margin-bottom: 150px;
  }
  @include breakpoint(xlarge down){
    margin-top:100px;
    margin-bottom:160px;
    &:last-of-type {
      margin-top:200px;
      margin-bottom: 50px;
    }
  }
  @include breakpoint(small down){
    margin-top:60px;
    margin-bottom:80px;
    &:last-of-type {
      margin-top:120px;
      margin-bottom: 50px;
    }
  }
  opacity: 0;
  transform: translateY(20px);
  will-change: opacity, transform;
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1), 
              transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  
  &.fade-in-up {
    opacity: 1;
    transform: translateY(0);
  }

  &.fade-out {
    opacity: 0;
    transform: translateY(20px);
  }
}

p {
  font-size:24px;
  line-height:36px;
  margin-bottom:45px;
  @include breakpoint(xlarge down){
    font-size:20px;
    line-height:30px;
    margin-bottom:38px;
  }
  @include breakpoint(small down){
    font-size:16px;
    line-height:22px;
    margin-bottom:25px;
  }
}

.primary-color {
  color: $primary;
}

.mega {
  font-weight: bold;
  letter-spacing: -0.052em;
  line-height: 0.8;
  font-size: clamp(2rem, 8vw, 4rem);
  margin-bottom: 2rem;
  contain: layout style paint;
}

.submega {
  font-weight:bold;
  letter-spacing: -0.052em;
  line-height:0.8;
}

h2 {
  font-size:100px;
  line-height:120px;
  color:$primary;
  font-weight: bold;
  letter-spacing: -0.052em;
  @include breakpoint(xlarge down){
    font-size:70px;
    line-height: 90px;
  }
  @include breakpoint(small down){
    font-size:40px;
    line-height: 50px;
  }
}

h3 {
  color: $primary;
  font-size:65px;
  line-height: 70px;
  font-weight:bold;
  margin-bottom:30px;
  @include breakpoint(xlarge down){
    font-size:45px;
    line-height: 55px;
  }
  @include breakpoint(large down){
    margin-bottom:15px;
  }
  @include breakpoint(small down){
    font-size:30px;
    line-height: 40px;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px) rotate(0deg);
  }
  25% {
    transform: translatey(-15px) rotate(1deg);
  }
  50% {
    transform: translatey(-20px) rotate(-1deg);
  }
  75% {
    transform: translatey(-15px) rotate(0.5deg);
  }
  100% {
    transform: translatey(0px) rotate(0deg);
  }
}

.floating-mp {
  width: 65%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8vw;
  animation: float 8s ease-in-out infinite;
  transform: translatey(0px);
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1)) 
         drop-shadow(0 6px 6px rgba(0, 0, 0, 0.1));
  will-change: transform;
  aspect-ratio: 800/600;
  content-visibility: auto;
  contain: layout style paint;
}

@keyframes shadowPulse {
  0% {
    opacity: 0.7;
    transform: scale(1);
    filter: blur(10px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
    filter: blur(15px);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
    filter: blur(10px);
  }
}

.shadow {
  width: 40%;
  height: 40px;
  background: rgba($primary, 0.1);
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  animation: shadowPulse 8s ease-in-out infinite;
  will-change: transform, opacity, filter;
}

.main-callout {
  font-size:45px;
  line-height:55px;
  position:relative;
  max-width:660px;
  @include breakpoint(xlarge down){
    font-size:36px;
    line-height: 46px;
  }
  @include breakpoint(large down){
    font-size:36px;
    line-height:46px;
    max-width:86%;
  }
  @include breakpoint(small down){
    font-size:20px;
    line-height:26px;
    max-width:100%;
    br {
      display:none;
      &:first-of-type {
        display:block;
      }
    }
  }
}

.icon-screen {
  position: absolute;
  bottom: 10px;
  right: -120px;
  width: 150px;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.1));
  
  @include breakpoint(small down) {
    bottom: auto;
    top: -50px;
    width: 85px;
    right: -10px;
  }
}

.bolt {
  padding: 0 60px;
  width:100%;
  @include breakpoint(small down){
    padding: 0 0 0 20px;
  }
}

.clear-btn {
  color: $primary;
  cursor: pointer;
  display:inline-block;
  &:after {
    margin-top:5px;
    content: '';
    display:block;
    height:2px;
    width:1%;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    &:after {
      width:100%;
      background: $primary;
    }
  }
}

.work-section {
  width: calc(100% + 190px);
  margin-left: -95px;
  margin-right: -95px;
  margin-top:-70px;
  position:relative;
  z-index:10;
  background:white;
  perspective-origin: 50% 50%;
  a {
    margin-bottom:0;
    display:block;
    &:after {
      display:none !important;
    }
  }
  @include breakpoint(large down){
    width:calc(100% + 110px);
    margin-left:-55px;
    margin-right:-55px;
    margin-top:-40px;
  }
  @include breakpoint(small down){
    width:calc(100% + 50px);
    margin-left:-25px;
    margin-right:-25px;
    margin-top:-30px;
  }
}

.work-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 0px;
  background: rgba($primary, 0.1);
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: rgba($primary, 0.1);
    will-change: transform;
    contain: layout style paint;
  }
  
  .work-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($primary, 0.9);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 1rem;
    z-index: 2;
    
    @include breakpoint(small only) {
      padding: 0.5rem;
      background: rgba($primary, 0.85);
    }
  }
  
  &:hover {
    .work-overlay {
      opacity: 1;
    }
    
    img {
      transform: scale(1.05);
    }
  }
  
  h4 {
    color: white;
    margin: 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    @include breakpoint(small only) {
      font-size: 1.2rem;
    }
  }
  
  p {
    color: white;
    margin: 0.5rem 0;
    text-align: center;
    font-size: 1rem;
    
    @include breakpoint(small only) {
      font-size: 0.9rem;
      margin: 0.25rem 0;
    }
  }
}

.slide-visit {
  position:absolute;
  bottom:0;
  background:$primary;
  color:white;
  width:100%;
  padding:15px 20px;
  display:block;
  text-align:center;
  font-size:18px;
  text-transform: uppercase;
  font-weight:600;
  bottom:-60px;
  transition:all 0.2s ease-in-out;
  &:after {
    display:none;
  }
  &:hover {
    color:white;
    background: darken($primary, 5%);
    &:after {
      display:none;
    }
  }
}

.work-follow {
  position:absolute;
  z-index:100;
  height:80px;
  width:100%;
  background:white;
  //transform: translateY(80px);
}

.testimonial {
  padding:90px;
  background: $primary;
  color:white;
  width: 100%;
  @include breakpoint(xlarge down){
    padding:60px;
  }
  @include breakpoint(small down){
    padding:65px 30px 45px;
  }
  p {
    font-size:50px;
    line-height:65px;
    margin-bottom:80px;
    margin-top: 0;
    @include breakpoint(xlarge down){
      font-size:32px;
      line-height: 42px;
    }
    @include breakpoint(small down){
      font-size:20px;
      line-height: 28px;
      margin-bottom:40px;
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
  a {
    color: white;
    display:inline-block;
    &:after {
      margin-top:5px;
      content: '';
      display:block;
      height:2px;
      width:1%;
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      &:after {
        width:100%;
        background: white;
      }
    }
  }
  .author {
    font-size:40px;
    line-height:1;
    margin-bottom: 0;
    font-weight:600;
    @include breakpoint(xlarge down){
      font-size:32px;
      line-height: 1;
    }
    @include breakpoint(small down){
      font-size:20px;
      line-height: 1;
    }
  }
  .author-title {
    font-size:32px;
    line-height:36px;
    margin-bottom: 0;
    @include breakpoint(xlarge down){
      font-size:26px;
      line-height: 30px;
    }
    @include breakpoint(small down){
      font-size:18px;
      line-height: 1;
    }
  }
}
.icon-plus {
  width:42px;
  height:42px;
  color: transparent;
  overflow:hidden;
  display:block;
  background-size: contain;
  background-image: url(../img/icon-plus.svg);
  cursor: pointer;
  position:absolute;
  top:30px;
  right:30px;
  transition:all 2s ease-in-out;
  &:hover {
    transform:rotate(420deg) scale(1.1);
    color: transparent;
  }
  &:active, &:focus {
    color: transparent !important;
  }
  @include breakpoint(medium down){
    width:33px;
    height:33px;
  }
  @include breakpoint(medium down){
    top:15px;
    right:15px;
    width:20px;
    height:20px;
  }
}

.contact-form {
  padding: 50px;
  h3 {
    font-size:3rem;
    line-height: 1;
  }
  @include breakpoint(small down){
    padding: 20px;
  }
}

.button {
  font-weight: bold;
  text-transform: uppercase;
}

.message-box {
  height:200px;
}

.bolts {
  position:relative;
  @include breakpoint(large down){
    width:40%;
    margin-left:auto;
    margin-bottom:-60px;
  }
  @include breakpoint(small down){
    width:30%;
    margin-left:auto;
    margin-bottom:-30px;
  }
}

.bolt-mini {
  position: absolute;
  bottom: 170px;
  left: 20px;
  transform-origin: center;
  animation: zap 3s ease-in-out infinite;
  opacity: 0;
  will-change: transform, opacity;
  
  @include breakpoint(large down) {
    width: 10%;
    bottom: 100px;
  }
  @include breakpoint(medium down) {
    width: 10%;
    bottom: 60px;
    left: 40px;
  }
  @include breakpoint(small down) {
    display: none;
  }
}

@keyframes zap {
  0% {
    opacity: 0;
    transform: rotate(-30deg) scale(1) translateY(-30px);
  }
  20% {
    opacity: 0;
    transform: rotate(-30deg) scale(1.2) translateY(-20px);
  }
  30% {
    opacity: 1;
    transform: rotate(-25deg) scale(1.4) translateY(3px);
  }
  35% {
    transform: rotate(-35deg) scale(1.5) translateY(0);
  }
  40% {
    opacity: 1;
    transform: rotate(-30deg) scale(1.5) translateY(0);
  }
  50% {
    opacity: 0;
    transform: rotate(-30deg) scale(1) translateY(0);
  }
  100% {
    opacity: 0;
    transform: rotate(-30deg) scale(1) translateY(0);
  }
}

.faq-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
}

.faq-question {
  font-size: 1.4rem;
  color: black;
  margin: 0;
  cursor: pointer;
  display: block;
}

.faq-answer {
  display: none; /* Initially hide answers */
  margin-top: 10px;
  font-size: 1rem;
  color: var(--text-color);
}

/* Optional: Add hover effect for questions */
.faq-question:hover {
  text-decoration: underline;
}
ul {
  margin-bottom: 30px;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.accordion {
  background: transparent;
  margin-top: 2rem;

  .accordion-item {
    background: transparent;
    margin-bottom: 1.5rem;
    border: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-title {
    color: $primary;
    border: none;
    background: transparent;
    font-size: 28px;
    line-height: 1.3;
    padding: 1rem 3rem 1rem 0;
    font-weight: 500;
    transition: color 0.3s ease;
    position: relative;

    &::before {
      content: '+';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 42px;
      line-height: 1;
      color: $primary;
      transition: transform 0.3s ease;
    }

    &:hover {
      background: transparent;
      color: darken($primary, 10%);
    }

    @include breakpoint(medium down) {
      font-size: 24px;
      padding: 0.75rem 2.5rem 0.75rem 0;

      &::before {
        font-size: 36px;
      }
    }

    @include breakpoint(small down) {
      font-size: 20px;
      padding: 0.5rem 2rem 0.5rem 0;

      &::before {
        font-size: 32px;
      }
    }
  }

  .accordion-content {
    border: none;
    padding: 0 3rem 1.5rem 0;
    background: transparent;
    color: $black;
    font-size: 20px;
    line-height: 1.6;

    @include breakpoint(medium down) {
      font-size: 18px;
      padding: 0 2.5rem 1rem 0;
    }

    @include breakpoint(small down) {
      font-size: 16px;
      padding: 0 2rem 1rem 0;
    }
  }

  .is-active > .accordion-title::before {
    transform: translateY(-50%) rotate(45deg);
  }
}

.portfolio-grid {
  margin-top: 2rem;
  
  .cell {
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateY(30px);
    will-change: opacity, transform;
    transition: opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1), 
                transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    
    &.fade-in-up {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.button.hollow.white {
  border: 2px solid rgba(255, 255, 255, 0.9);
  color: white;
  padding: 0.85em 1.5em;
  margin: 1rem 0 0;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
  z-index: 1;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    background: white;
    transition: height 0.3s ease-in-out;
    z-index: -1;
  }
  
  &:hover {
    color: $primary;
    border-color: white;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    
    &::before {
      height: 450%;
    }
  }
  
  &:active {
    transform: translateY(0);
  }
  
  @include breakpoint(small only) {
    padding: 0.5em 1em;
    margin-top: 0.5rem;
  }
}

.button.primary {
  background-color: $primary;
  
  &:hover {
    background-color: darken($primary, 10%);
  }
}

.button.hollow.white {
  &:hover {
    color: $primary;
  }
}

a {
  &:after {
    background-color: $primary;
  }
  
  &:hover {
    color: $primary;
  }
}

.icon-plus {
  &:hover {
    background-color: rgba($primary, 0.1);
  }
}

:last-child:not(.is-active)>.accordion-title, :last-child>.accordion-content:last-child {
  border-bottom: none !important;
}

@keyframes screenFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.contact-placeholder {
  color: $primary;
  opacity: 0.7;
  font-style: italic;
}

.simple-container {
  padding: 20px;
  max-width: 600px;
  width:90%;
  margin-left: auto;
  margin-right: auto;
}